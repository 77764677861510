import React from 'react';
import { Box, Divider, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({ spacing, typography, palette }) => ({
  root: {
    padding: `${spacing(8)}px 0 ${spacing(8)}px`,
  },
  title: {
    fontWeight: typography.fontWeightBold,
    fontSize: typography.h4.fontSize,
  },
  description: {
    fontWeight: typography.fontWeightRegular,
  },
  divider: {
    width: 50,
    height: 3,
    backgroundColor: palette.text.primary,
    marginTop: spacing(3),
  },
}));

const Jumbotron = () => {
  const styles = useStyles();

  return (
    <Box className={styles.root} display="flex" flexDirection="column" alignItems="center">
      <Typography className={styles.description}>거북이같은 개발자의 집필 공간</Typography>
      <Typography className={styles.title}>Evans Library</Typography>
      <Divider className={styles.divider} variant="middle" />
    </Box>
  );
};

export default Jumbotron;
