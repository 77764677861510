import React from 'react';
import { graphql } from 'gatsby';
import PostsPage from 'containers/PostsPage';

const PostsTemplate = (props: any) => {
  return <PostsPage {...props} />;
};

export default PostsTemplate;

export const pageQuery = graphql`
  query PostsQuery($skip: Int!, $limit: Int!, $lang: String!) {
    allMarkdownRemark(
      skip: $skip
      limit: $limit
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { lang: { eq: $lang } } }
    ) {
      edges {
        node {
          ...PostQueryFragment
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`;
