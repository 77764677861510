import { Chip, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import PostIntroCard from 'src/components/Cards/Posts';

const useStyle = makeStyles<Theme>(({ palette, typography }) => ({
  badge: {
    color: palette.common.white,
    fontWeight: typography.fontWeightBold,
  },
}));

interface Props {
  onClick?: () => void;
}
const InflearnBannerCard = ({ onClick }: Props) => {
  const styles = useStyle();
  return (
    <a
      href="https://www.inflearn.com/course/실무-정규식?inst=2abd2192"
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      <PostIntroCard
        badge={<Chip color="primary" size="small" label="강의" className={styles.badge} />}
        title="정규식과 함께 생산성 200%, 2x 엔지니어가 되어보자"
        description={`이 강의에는 정규식이 제공하는 간단한 기능들과, 필자가 실무에서 직접 접했던 문제들을 어떻게 정규식을 사용하여 효율적으로 해결할 수 있었는지에 대한 내용을 담았다.\n더 나아가 단순히 정규식의 기능만을 나열하는 것이 아니라 정규식이라는 기술을 누가, 어떠한 이유로 만들게 되었는지 그 원리까지 설명하고 있다.`}
        thumbnail="https://cdn.inflearn.com/public/courses/326270/cover/1a58fc59-bed0-42e4-9c19-c1a3faab1003/326270-eng.png"
      />
    </a>
  );
};

export default InflearnBannerCard;
